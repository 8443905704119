var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "text-center", attrs: { "fill-height": "" } },
    [
      _c(
        "v-row",
        {
          staticClass: "opacity-5",
          attrs: { "align-content": "center", justify: "center" },
        },
        [
          _c(
            "v-col",
            { staticClass: "text-center", attrs: { cols: "12" } },
            [
              _vm._t("default", function () {
                return [_vm._v("No data available")]
              }),
            ],
            2
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-icon", { attrs: { large: "" } }, [
                _vm._v(_vm._s(_vm.icon || "mdi-dots-square")),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }